import React from 'react';
import { Layout, SEO } from 'components';
import { graphql } from 'gatsby';
import { Container, Stack } from '@tymate/margaret';
import { PageTitle, ArticleBody, SectionImage } from 'ui';

const OffersPage = ({ data }) => (
  <Layout>
    <SEO
      title={data?.datoCmsOfferPage?.seo?.title}
      description={data?.datoCmsOfferPage?.seo?.description}
    />
    <Container variant="main">
      <Stack direction={{ default: 'column', desktop: 'row' }} gutterSize={2}>
        <div>
          <PageTitle>{data?.datoCmsOfferPage?.title}</PageTitle>
          <ArticleBody
            id="body"
            dangerouslySetInnerHTML={{
              __html:
                data?.datoCmsOfferPage?.contentNode?.childMarkdownRemark?.html,
            }}
          />
        </div>
        <SectionImage>
          <img
            src={data?.datoCmsOfferPage?.image?.url}
            alt={data?.datoCmsOfferPage?.image?.alt}
          />
        </SectionImage>
      </Stack>
    </Container>
  </Layout>
);

export const query = graphql`
  query {
    datoCmsOfferPage {
      title
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      image {
        url
        alt
      }
      seo {
        title
        description
      }
    }
  }
`;

export default OffersPage;
